@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.stop-scrolling{
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Vidaloka-Regular";   /*Can be any text*/
  src: local("Vidaloka-Regular"),
    url("./fonts/Vidaloka-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lora-Medium";   /*Can be any text*/
  src: local("Lora-Medium"),
    url("./fonts/Lora-Medium.ttf") format("truetype");
}


.Vidaloka{
 font-family: "Vidaloka-Regular";
}


.Lora{
  font-family: "Lora-Medium";
}

.stop-scrolling{
  overflow: hidden;
 }
/*  
#HeroMenu{
  /* background-image: url('./static/MenuHero.jpg');
  background-origin: content-box;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; 
  scale: 0.95; 
} */ 
.bgimage{
  filter: brightness(0.6);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation : zoom 8s linear infinite;
  overflow: hidden;
}



#bgimagemenu{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top; 
  overflow: hidden;
}
#imagehov{
  animation: from-not 1s 1 linear ;
}
.text-animation{
  animation: from-under1 1s 1 linear ;
}

.fromUnder{
  animation: from-under2 1s 1 linear;
}
.lateralAnimation{
  animation: from-right 1s 1 linear;
}

.animationButton:hover{
  animation: buttonFromDown 1s 1 linear;

}
.admintranstion{
  transition: all 1s;
}
.slideimganimation{

  animation: from-left  2s 1 linear,from-not2 2s 1 linear;
 


}



@keyframes zoom {
 
from{
  transform: scale(1);
}
 
  to {
    transform: scale(1.2);
  }
 
}
@keyframes from-under1 {

  from{
    margin-top: 30px;
  }
   
  }
  @keyframes from-left {

    from{
      margin-left: -30px;
    }
   
     
    }
@keyframes from-not2 {
  from{
    filter: brightness(0.1);
  }
  to{
    filter: brightness(1);

  }
}
    
@keyframes from-under2 {

from{
  margin-top: 60px;
}

 
}
  
@keyframes from-not {

  from{
    filter: brightness(0);
  }
  to{
    filter: brightness(0.75);

  }
}  

@keyframes from-right {

  from{
    margin-right: -325px;
  }
   
  }

@keyframes buttonFromDown{
  
  to{
    margin-right: -2px;
    margin-left:2px;
    background-color: orange
  }
}


